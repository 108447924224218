/* SWEET ALERT 2*/
.swal2-confirm{
    background-color: #337ab7 !important;
    color: white !important;
  }
  
.swal2-cancel{
    background-color: #777 !important;
    color: white !important;
  }

.swal2-container{
  z-index: 3000 !important;
}

.swal2-popup {
  width: 500px;
  padding: 20px;
  background: rgb(255, 255, 255);
  display: block;
}

.swal2-icon,
.swal2-actions {
  font-size: 1.8rem !important;
}

.swal2-html-container {
  font-size: 1.8rem !important;
  margin: 0;
}

/* BASIC */
.mTB20{margin-top:20px;margin-bottom:20px;}
.mL10{margin-left: 10px;}
.navbar-header {
  padding-left: 10px !important;
}
.text-redBU{color: red;}
.text-primary{color: #337ab7;}
.text-green{color: green;}

tr {cursor: default !important;}

.divFilter div button{float:left; margin-right: 10px;}
.resetFilter{margin-left:8px;}

.btn-circle{
  width: 20px;
  height: 20px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
  cursor: default;
}

.btn-opMob{
  background-color: orange !important;
}
.btn-eCom{
  background-color: orangered !important;
}
.btn-riv{
  background-color: #08c108 !important;
}
.btn-desk{
  background-color: #1d1d9a !important;
}
.btn-api{
  background-color: violet !important;
}
.btn-onTruck{
  background-color: lightskyblue !important;
}

bs-datepicker-container, bs-daterangepicker-container {z-index: 3000 !important;} 

/*SELECT FILTER E INPUT FILTER*/
.form-group {
  height: 60px;margin-bottom: 10px;
}

.form-control, input {
  height: 30px !important;
}

label{
  margin-bottom: 2px;
}

.ibox-title h2 {
  padding-left: 10px;
}

/*BUTTON DATEPICKER*/
.selectiongroup, .selbtngroup, .btnpicker,.selectiongroup input {
  height: 28px !important;
}
.sidebar-collapse{
  box-shadow:0 0px 0px 0 rgba(0, 0, 0, 0.14), 0 0px 0px -2px rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.12)
}

/*MEDIA QUERY*/
@media (max-width: 992px) {
  .md-skin .navbar-top-links li a{
    padding:15px 45px;
  }
}

.ptkDisabled {
  pointer-events: none;
  opacity: 0.7;
}

.form-control.input-number.select-input {
  height: 3.4rem !important;
  text-align: center;
}